export default {
  data() {
    return {
      tabIndex: +this.$route.query.tab || 0,
    }
  },

  watch: {
    tabIndex(val) {
      if (val !== this.tabIndex) {
        this.$router.replace({ query: { ...this.$route.query, tab: val } })
      }
    },
  },

  created() {
    if (this.$route.query.query) {
      this.$router.push({ query: { ...this.$route.query, tab: this.tabIndex } })
    }
  },
}
