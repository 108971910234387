<template>
  <b-card
    text-variant="white"
    :style="backgroundImg!=''?`background-image:url(${backgroundImg});`: `background-color:${backgroundColor};`"
  >
    <div
      class="d-flex"
    >
      <b-avatar :src="iconCard" />
      <div
        class="coins"
      >
        <span>
          {{ cards.currency }}
        </span>
        <br>
        <span>{{ cards.name }}</span>
      </div>

    </div>

    <div class="my-2">
      <span
        class="h4 font-weight-bold"
        style="color: white;margin-right: 0.3rem;"
      >{{ $t('balance') }} :</span>
      <span>{{ cards.balance }}</span>
    </div>

    <div class="d-flex">

      <b-button
        class="d-flex justify-content-center align-items-center m-auto"
        size="sm"
        variant="light"
        :to="depositLink"
      >
        <span>{{ $t('deposit') }}</span>
      </b-button>
      <b-button
        class="d-flex justify-content-center align-items-center m-auto"
        size="sm"
        variant="dark"
        :to="widrawLink"
      >
        <span> {{ $t('widraw') }} </span>
      </b-button>
      <b-button
        class="d-flex justify-content-center align-items-center m-auto"
        size="sm"
        variant="dark"
        :to="historyLink"
      >
        <span>{{ $t('history') }}</span>
      </b-button>
    </div>

  </b-card>
</template>

<script>
import {
  BCard,
  BAvatar,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BAvatar,
    BButton,

  },
  props: {
    cards: {
      type: Object,
      default: () => {},
    },
    backgroundColor: {
      type: String,
      default: '',
    },
    backgroundImg: {
      type: String,
      default: '',
    },
    iconCard: {
      type: String,
      default: '',
    },
    cardNumber: {
      type: String,
      default: '',
    },
    depositLink: {
      type: String,
      default: '',

    },
    widrawLink: {
      type: String,
      default: '',
    },
    historyLink: {
      type: String,
      default: '',
    },
  },
  data() {
    return {

    }
  },

}

</script>
  <style scoped>
  .coins {
    margin-left: 0.4rem;
  }
  </style>
