<template>
  <b-overlay
    :show="show"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <b-card>
      <b-row align-v="center">
        <b-col
          cols="12"
          md="6"
        >
          <b-form-input
            v-model="filter.search"
            :placeholder="$t('search')"
          />
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mt-2 mt-md-0"
        >
          <b-form-checkbox
            v-model="filter.showWithBalance"
            name="check-button"
            switch
          >
            {{ $t('OnlyDisplayWalletsWithBalance') }}
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row
        v-if="cryptoWallets.length > 0"
        class="mt-5"
      >
        <b-col
          v-for="(item, index) in filtredWallets"
          :key="index"
          cols="12"
          md="6"
          xl="4"
        >
          <b-card
            class="rounded-lg"
            style="overflow:hidden;box-shadow: 0px 0px 15px -5px rgba(150, 150, 150, 0.6) !important;"
          >
            <div class="bubble" />
            <b-row align-h="between">
              <b-col class="d-flex align-center">
                <b-avatar :src="getCoinAvatar(item.token)" />
                <div class="coins mx-2 d-flex flex-column align-center my-auto">
                  <h6>{{ (item.name ? item.name : item.name) }}</h6>
                  <!-- <span>{{ item.network_name }}</span> -->
                </div>
              </b-col>

            </b-row>
            <hr style="z-index:-1">

            <div
              :class="$store.state.appConfig.layout.skin == 'dark' ? 'text-white' : ''"
              class="h3 m-1"
            >
              <span>{{ item.quantity &&
                helpers.numberWithCommas(helpers.toFixed(item.quantity, item.currency_network[0].exchange.quantity_decimal_for_wallet)) }}</span>
              <span class="mx-1">{{ item.token }}</span>
            </div>
            <!-- <b-row
            align-h="between"
            align-v="center"
            class="mb-2 mx-1"
          >
            <b-col class="text-secondary">
              <span>$</span>
              <span>1.1231234</span>
            </b-col>
            <b-col cols="auto">
              <b-badge
                variant="danger"
                style="border-radius:2rem !important"
              >
                -2.1%
              </b-badge>
            </b-col>
          </b-row> -->
            <hr>
            <b-row
              class="d-flex mt-2"
              align-h="center"
            >
              <b-col cols="5">
                <b-button
                  class="d-flex justify-content-center align-items-center m-auto customHover"
                  :variant="item.depositable ? 'success' : ''"
                  :disabled="!item.depositable"
                  :to="item.currency_network[0].is_fiat ? `/${$i18n.locale}/customer-wallets/fiat/deposit/online/${item.token ? item.token : item.token}` : `/${$i18n.locale}/customer-wallets/crypto-deposit/${(item.token ? item.token : item.token).toLowerCase()}/${item.token.toLowerCase()}`"
                >
                  <span>{{ $t('crypto_wallet_deposit') }}</span>
                </b-button>
              </b-col>
              <b-col cols="5">
                <b-button
                  class="d-flex justify-content-center align-items-center m-auto customHover"
                  :variant="item.withdrawable ? 'danger' : ''"
                  :disabled="!item.withdrawable"
                  :to="item.currency_network[0].is_fiat ? `/${$i18n.locale}/customer-wallets/fiat/withdraw/${item.token ? item.token : item.token}` : `/${$i18n.locale}/customer-wallets/crypto-withdraw/${(item.token ? item.token : item.token).toLowerCase()}/${item.token.toLowerCase()}`"
                >
                  <span> {{ $t('crypto_wallet_withdrawal') }} </span>
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>
<script>
import {
  BCol,
  BRow,
  BButton,
  BCard,
  BAvatar,
  BOverlay,
} from 'bootstrap-vue'
import CustomerWalletApis from '@/modules/crypto/services/apis/customer-wallet'
import { toFixed, numberWithCommas } from '@/utils'

const customerWallet = new CustomerWalletApis()
let interval
export default {
  components: {
    BCol,
    BRow,
    BButton,
    BCard,
    BAvatar,
    BOverlay,
  },

  data() {
    return {
      backgroundImg: '',
      backgroundColor: 'black',
      show: true,
      cryptoWallets: [],
      helpers: {
        toFixed,
        numberWithCommas,
      },
      filter: {
        showWithBalance: false,
        search: '',
      },
    }
  },
  computed: {

    filtredWallets() {
      let filtred = this.filter?.showWithBalance ? this.cryptoWallets.filter(item => item.quantity > 0) : this.cryptoWallets
      filtred = this.filter?.search ? filtred.filter(item => item.token.toLowerCase().includes(this.filter?.search) || item.name.toLowerCase().includes(this.filter?.search)) : filtred
      return filtred
    },
  },

  mounted() {
    this.getwallets()
    interval = setInterval(() => {
      this.getwallets()
    }, 60000)
  },

  beforeDestroy() {
    clearInterval(interval)
  },

  methods: {
    getwallets() {
      customerWallet.getUserWallets().then(res => {
        const wallets = res.data.results.map(item => ({ ...item, depositable: !!item.currency_network.find(i => i.is_deposit), withdrawable: !!item.currency_network.find(i => i.is_withdrawal) }))
          .sort((a, b) => (a.currency_network[0].exchange.position - b.currency_network[0].exchange.position))

        this.cryptoWallets = wallets
        this.show = false
      })
    },
    getCoinAvatar(coin) {
      let img = ''

      try {
        // eslint-disable-next-line import/no-dynamic-require, global-require
        img = require(`@/assets/images/currencies/${coin.toLowerCase()}.svg`)
        return img
      } catch (e) {
        // eslint-disable-next-line import/no-dynamic-require, global-require
        img = require('@/assets/images/currencies/default.svg')
        return img
      }
    },
  },

}
</script>
<style scoped>
.custom-btn {
  font-size: 12px;
}

.createWallet {
  width: 4.3rem;
  transition: all .3s ease;
  display: flex;
  align-items: center;
}

.createWallet:hover {
  width: 100%;
}

.createSpan:nth-child(1) {
  transition: all .7s cubic-bezier(.23, -0.04, .77, -1.49)
}

.createSpan:nth-child(2) {
  visibility: hidden;
  transition: all .3s;
  white-space: nowrap;
}

.createWallet:hover .createSpan:nth-child(2) {
  visibility: visible;
}

.createWallet:hover .createSpan:nth-child(1) {
  transform: rotate(90deg);
}

/* .bubble{
  position: absolute;
  top:-1rem;
  right:-1rem;
  width: 14rem;
  height:14rem;
  border-radius: 50%;
  background: #fafbffdc !important;
} */

.customHover {
  transition: all .2s;
  backface-visibility: hidden;
}

/* .customHover:hover{
  transform: scale(1.1) translateZ(0);
} */

.createWalletBtn {
  width: 250px;
}

@media only screen and (max-width: 600px) {
  .createWalletBtn {
    width: 100%;
  }
}

.crypto-card {
  background-color: #ffb065;
  padding: 11px;
  border-radius: 4px;
  opacity: 90%;
}
</style>
